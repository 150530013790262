<script setup lang="ts">
import { computed } from "vue";

const props = withDefaults(
  defineProps<{
    isPrimary?: boolean;
    isStory?: boolean;
    href?: string;
    hasAnimation?: boolean;
  }>(),
  {
    isPrimary: false,
    isStory: false,
    href: undefined,
    hasAnimation: true,
  }
);

const tag = computed(() => {
  if (props.href) return "a";
  else return "button";
});

const buttonType = computed(() => {
  return !props.isStory && !props.href ? "button" : undefined;
});
</script>

<template>
  <NuxtLink
    v-if="isStory"
    :to="href"
    class="appearance-none inline-block p-0 text-font transform transition-transform"
    :class="{
      'hover:scale-110': hasAnimation,
      'hover:underline': !hasAnimation,
      'bg-highlight font-semibold py-2 px-4': isPrimary,
      'bg-transparent': !isPrimary,
    }"
    v-bind="$attrs"
    >
      <slot></slot>
  </NuxtLink>
  <component
    v-else
    :is="tag"
    :type="buttonType"
    :href="href"
    class="appearance-none inline-block transform transition-transform p-0 text-font"
    :class="{
      'hover:scale-110': hasAnimation,
      'hover:underline': !hasAnimation,
      'bg-highlight font-semibold py-2 px-4': isPrimary,
      'bg-transparent': !isPrimary,
    }"
    v-bind="$attrs"
    ><slot></slot
  ></component>
</template>
