export const iubendaConfig: { [key: string]: { [key: string]: string | number } } = {
  de: {
    lang: "de",
    id: 89883672,
    url: "https://www.bivignano.com/cookie-richtlinie",
  },
  en: {
    lang: "en-GB",
    id: 47671911,
    url: "https://www.bivignano.com/en/cookie-richtlinie",
  },
};