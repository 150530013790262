<script setup lang="ts">
import { provide } from 'vue';
import { backgroundColorInjectKey } from '../helpers/injectKeys';

const props = defineProps<{
  backgroundColor?: BackgroundColorKey;
}>();

provide(backgroundColorInjectKey, props.backgroundColor);
</script>

<template>
  <section
    class="content-section px-4 py-8 xs:px-8 xs:py-12 lg:py-16 xl:px-12 xl:py-20"
    :class="{
      'bg-brandDark': backgroundColor === 'dark',
      'bg-brandLight': backgroundColor === 'light',
      'bg-brandMedium': backgroundColor === 'medium',
    }"
  >
    <div class="content-section__content"><slot></slot></div>
  </section>
</template>

<style lang="scss" scoped>
.content-section {
  &__content {
    @screen lg {
      max-width: 90%;
      margin: 0 auto;
    }

    @screen xl {
      max-width: 80%;
    }
  }
}

.content-section .content-section {
  padding: 0;
}
</style>
