<script setup lang="ts">
const props = withDefaults(defineProps<{
  id: number | string;
  filename: string;
  alt?: string;
  title?: string;
  aspectRatio?: string;
  focus?: string;
  useBorder?: boolean;
  isCritical?: boolean;
  sizes?: string;
  isOgImage?: boolean;
}>(), {
  sizes: "(min-width: 1280px) 1280px, 100vw",
});

const src = computed(() => {
  return props.filename.replace("https://a.storyblok.com/", "");
});

const { isNativeFallback } = useNativeFallback();
const config = useRuntimeConfig();

if (props.isOgImage) {
  useHead({
    meta: [
      {
        property: "og:image",
        content: `${config.public.NUXT_TWICPICS_DOMAIN}/${src.value}`
      }
    ]
  });
}
</script>

<template>
  <TwicPicture v-if="isCritical" 
    :src="src"
    :focus="focus?.split(':')[0]"
    :alt="alt"
    :title="title"
    eager="true"
    :ratio="aspectRatio ?? 'none'"
    v-bind="$attrs"
    :sizes="sizes"
    fetchpriority="high"
  />
  <img
    v-else-if="isNativeFallback"
    :src="filename"
    v-bind="$attrs"
    :alt="alt"
    :title="title"
    class="w-full object-cover overflow-hidden"
    :class="{ 'base-image-border': useBorder }"
    loading="lazy"
  />
  <TwicImg
    v-else
    v-bind="$attrs"
    :class="[
      'overflow-hidden',
      { 'no-aspect-ratio': !aspectRatio },
      { 'base-image-border': useBorder },
    ]"
    :src="src"
    :alt="alt"
    :title="title"
    :focus="focus?.split(':')[0]"
    :ratio="aspectRatio"
    placeholder="maincolor"
    handleShadowDom="true"
  />
</template>

<style lang="scss">
.no-aspect-ratio > .twic-w {
  position: inherit;
  padding-top: 0;
}

.base-image-border {
  @apply border-2 border-brandDark rounded-xl;

  & img {
    @apply rounded-xl;
  }
}
</style>
