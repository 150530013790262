import { default as _91_46_46_46slug_93Qv3uKeTLMXMeta } from "/vercel/path0/pages/[...slug].vue?macro=true";
import { default as component_45stubUHgG5aOkzGMeta } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubUHgG5aOkzG } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: _91_46_46_46slug_93Qv3uKeTLMXMeta?.name ?? "slug___de___default",
    path: _91_46_46_46slug_93Qv3uKeTLMXMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93Qv3uKeTLMXMeta || {},
    alias: _91_46_46_46slug_93Qv3uKeTLMXMeta?.alias || [],
    redirect: _91_46_46_46slug_93Qv3uKeTLMXMeta?.redirect,
    component: () => import("/vercel/path0/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93Qv3uKeTLMXMeta?.name ?? "slug___de",
    path: _91_46_46_46slug_93Qv3uKeTLMXMeta?.path ?? "/de/:slug(.*)*",
    meta: _91_46_46_46slug_93Qv3uKeTLMXMeta || {},
    alias: _91_46_46_46slug_93Qv3uKeTLMXMeta?.alias || [],
    redirect: _91_46_46_46slug_93Qv3uKeTLMXMeta?.redirect,
    component: () => import("/vercel/path0/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93Qv3uKeTLMXMeta?.name ?? "slug___en",
    path: _91_46_46_46slug_93Qv3uKeTLMXMeta?.path ?? "/en/:slug(.*)*",
    meta: _91_46_46_46slug_93Qv3uKeTLMXMeta || {},
    alias: _91_46_46_46slug_93Qv3uKeTLMXMeta?.alias || [],
    redirect: _91_46_46_46slug_93Qv3uKeTLMXMeta?.redirect,
    component: () => import("/vercel/path0/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/deutsch/price_ii.php",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/english/price_ii.php",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/deutsch/horse.php",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/english/horse.php",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/deutsch/about.php",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/english/about.php",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/deutsch/contact.php",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/english/contact.php",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/italiano/contact.php",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/deutsch/trekking.php",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/english/trekking.php",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/deutsch/link.php",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/english/link.php",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/deutsch/location.php",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/english/location.php",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/italiano/location.php",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/deutsch/bivignano.php",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/english/bivignano.php",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/italiano/bivignano.php",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/deutsch/excursion.php",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/english/excursion.php",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/deutsch/news.php",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/english/news.php",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/deutsch/sport.php",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/english/sport.php",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/deutsch/apartment.php",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/english/apartment.php",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/deutsch/pool.php",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/english/pool.php",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/deutsch/direction.php",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/english/direction.php",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/deutsch/impressum.php",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/english/impressum.php",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/italiano/impressum.php",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/deutsch/press.php",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/english/press.php",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/deutsch/gastro.php",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/english/gastro.php",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/deutsch/privacy.php",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/english/privacy.php",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/italiano/privacy.php",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/deutsch/agb.php",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/english/agb.php",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/italiano/agb.php",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/deutsch/corona.php",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/english/corona.php",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/presse",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/en/presse",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/aktuelles",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/en/aktuelles",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/casa-bivignano",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/en/casa-bivignano",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/angebot/reiten/pferde",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/en/angebot/reiten/pferde",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/umgebung",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/en/umgebung",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/angebot/appartements",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/en/angebot/appartements",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/angebot/gastro",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/en/angebot/gastro",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/angebot/appartement/studio",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/en/angebot/appartement/studio",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/angebot/appartement/eckzimmer",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/en/angebot/appartement/eckzimmer",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/angebot/appartement/hexenhaeuschen",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/en/angebot/appartement/hexenhaeuschen",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/angebot/appartement/aprikosenhaeuschen",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/en/angebot/appartement/aprikosenhaeuschen",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/angebot/reiten/einfache-reitwochen",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/en/angebot/reiten/einfache-reitwochen",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/angebot/reiten/power-reitwoche",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/en/angebot/reiten/power-reitwoche",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/angebot/reiten/wanderritte",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/en/angebot/reiten/wanderritte",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/angebot/reiten/yoga-reitwoche",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/en/angebot/reiten/yoga-reitwoche",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/sitemap.xml",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: component_45stubUHgG5aOkzG
  }
]