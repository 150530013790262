<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    tag: string;
    text: string;
    emphasize?: boolean;
    responsive?: boolean;
  }>(),
  {
    emphasize: false,
    responsive: true,
  }
);

const fontsize = computed(() => {
  if (props.responsive) return `sc-${props.tag} lg:sc-${props.tag}-large`;
  return `sc-${props.tag}`;
});
</script>

<template>
  <component
    :is="tag"
    :class="[
      'hyphens-auto whitespace-pre-line',
      fontsize,
      {
        'font-optimus': emphasize,
        'font-normal': emphasize,
        'sc-sans-headline': !emphasize,
      },
    ]"
    >{{ text }}</component
  >
</template>
