import { iubendaConfig } from '@/helpers/iubenda';

export const useCookieBanner = () => {
  const { locale } = useI18n();

  useHead({
    link: [
      {
        rel: 'dns-prefetch',
        href: '//cdn.iubenda.com'
      },
      {
        rel: 'dns-prefetch',
        href: '//cs.iubenda.com'
      },
    ],
    script: [
      {
        tagPriority: 0,
        innerHTML: `
            var _iub = _iub || [];
            _iub.csConfiguration = {
              "askConsentAtCookiePolicyUpdate": true,
              "countryDetection": true,
              "enableFadp": true,
              "lang": "${iubendaConfig[locale.value].lang}",
              "perPurposeConsent": true,
              "preferenceCookie": { "expireAfter": 182 },
              "siteId": 3042897,
              "cookiePolicyId": ${iubendaConfig[locale.value].id},
              "cookiePolicyUrl": "${iubendaConfig[locale.value].url}",
              "banner": {"acceptButtonCaptionColor": "#000000", "acceptButtonColor": "#6DD9F3", "acceptButtonDisplay": true, "backgroundColor": "#F3B06D", "closeButtonDisplay": false, "customizeButtonCaptionColor": "#000000", "customizeButtonColor": "#F6F0E1", "customizeButtonDisplay": true, "explicitWithdrawal": true, "listPurposes": true, "linksColor": "#000000", "position": "bottom", "rejectButtonCaptionColor": "#000000", "rejectButtonColor": "#6DD9F3", "rejectButtonDisplay": true, "slideDown": false, "textColor": "#000000" }
            };
          `
      },
      {
        tagPriority: 0,
        src: "//cs.iubenda.com/sync/3042897.js",
        defer: true
      },
      {
        src: "//cdn.iubenda.com/cs/iubenda_cs.js",
        defer: true,
        async: true,
      },
    ],
  });
}

export default {
  useCookieBanner
}
