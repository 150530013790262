<template>
  <div>
    <NuxtLayout name="informational">
      <BaseContentSection background-color="light">
        <BaseNote>
          <BaseHeadline
            tag="h1"
            :emphasize="true"
            :text="t(`${messageCode}.headline`)"
          />
          <div class="prose lg:prose-lg">
            <p>
              <b>{{ $t(`${messageCode}.subline`) }}</b>
            </p>
            <p>
              {{ $t(`${messageCode}.paragraph`) }}
            </p>
          </div>
          <BaseButton
            class="mt-4 sm:mt-8"
            :is-primary="true"
            @click="handleError"
            >{{ $t(`${messageCode}.button`) }}</BaseButton
          >
        </BaseNote>
      </BaseContentSection>
      <BaseBanner :image="image"></BaseBanner>
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  error: Object,
});

const { t } = useI18n();

const messageCode = ref<number>(500);
const image = ref<{
  id: number | string;
  filename: string;
  alt?: string;
  title?: string;
  aspectRatio?: string;
  focus?: string;
}>({
  id: `500-error-image`,
  filename:
    "https://a.storyblok.com/f/120421/1328x747/4ea387839d/18671564_10211495629524374_4462795887227807699_o.jpeg",
});

if (props.error?.statusCode == 404) {
  messageCode.value = 404;
  image.value = {
    id: `404-error-image`,
    filename:
      "https://a.storyblok.com/f/120421/5472x3648/dc578f6323/nugget-django-macchia.JPG",
    focus: "2768x987:2769x988",
  };
}

const handleError = () => clearError({ redirect: "/" });
</script>
