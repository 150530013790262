<script setup lang="ts">
const props = defineProps<{
  image: {
    id: number | string;
    filename: string;
    alt?: string;
    title?: string;
    aspectRatio?: string;
    focus?: string;
    srcWidths?: Array<number>;
    sizes?: string;
  };
  headline?: StoryblokHeadline;
}>();

const hasHeadline = computed(() => (props.headline && props.headline?.text.length > 0));

const ratioDefault = computed(() => {
  if (hasHeadline.value) return "305/230 @400 385/230 @480 480/300 @640 640/300 @768 768/380 @960 960/380 @1024 1024/380 @1280 1280/380";
  return undefined;
});
</script>

<template>
  <section class="banner">
    <BaseImage
      class="banner__image"
      v-bind="image"
      :ratio="image.aspectRatio ?? ratioDefault"
      :sizes="image.sizes"
      :isCritical="hasHeadline"
      :isOgImage="true"
    ></BaseImage>
    <div v-if="headline" class="banner__text">
      <BaseHeadline v-bind="headline" :emphasize="true"></BaseHeadline>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.banner {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  overflow: hidden;

  &__image {
    grid-area: 1 / 1 / 2 / 2;
    z-index: 1;

    height: 230px;

    @screen xs {
      height: 300px;
    }

    @screen md {
      height: 380px;
    }
  }

  &__text {
    display: grid;
    grid-area: 1 / 1 / 2 / 2;
    z-index: 2;
    word-break: break-word;
    hyphens: auto;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    text-shadow: 2px 0px 2px rgba(0, 0, 0, 0.8);
    text-shadow: 0px 5px 20px #000000e6;
  }
}
</style>
