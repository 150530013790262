import { defineNuxtPlugin } from '#app/nuxt'
import { LazyIconLogo, LazyAppartementEntry, LazyBlokAppartementList, LazyBlokBanner, LazyBlokButton, LazyBlokContactAndMap, LazyBlokContentSection, LazyBlokDateEntry, LazyBlokDates, LazyBlokFaq, LazyBlokFaqs, LazyBlokGoogleReviews, LazyBlokHeadline, LazyBlokHeadlineAndText, LazyBlokImageSlider, LazyBlokIntro, LazyBlokLinkList, LazyBlokLinkListItem, LazyBlokMoodImage, LazyBlokNavigationDownload, LazyBlokNavigationGoogle, LazyBlokNewsList, LazyBlokNote, LazyBlokPrice, LazyBlokPriceList, LazyBlokPriceLists, LazyBlokProfileCard, LazyBlokProfileList, LazyBlokRichtext, LazyBlokTeaser, LazyBlokTeaserList, LazyBlokTeaserSection, LazyBlokTextMediaSection, LazyContentTypeArticle, LazyPageCookiePolicy, LazyPagePrivacy, LazyThePage, LazyIcon, LazyIconCSS } from '#components'
const lazyGlobalComponents = [
  ["IconLogo", LazyIconLogo],
["AppartementEntry", LazyAppartementEntry],
["BlokAppartementList", LazyBlokAppartementList],
["BlokBanner", LazyBlokBanner],
["BlokButton", LazyBlokButton],
["BlokContactAndMap", LazyBlokContactAndMap],
["BlokContentSection", LazyBlokContentSection],
["BlokDateEntry", LazyBlokDateEntry],
["BlokDates", LazyBlokDates],
["BlokFaq", LazyBlokFaq],
["BlokFaqs", LazyBlokFaqs],
["BlokGoogleReviews", LazyBlokGoogleReviews],
["BlokHeadline", LazyBlokHeadline],
["BlokHeadlineAndText", LazyBlokHeadlineAndText],
["BlokImageSlider", LazyBlokImageSlider],
["BlokIntro", LazyBlokIntro],
["BlokLinkList", LazyBlokLinkList],
["BlokLinkListItem", LazyBlokLinkListItem],
["BlokMoodImage", LazyBlokMoodImage],
["BlokNavigationDownload", LazyBlokNavigationDownload],
["BlokNavigationGoogle", LazyBlokNavigationGoogle],
["BlokNewsList", LazyBlokNewsList],
["BlokNote", LazyBlokNote],
["BlokPrice", LazyBlokPrice],
["BlokPriceList", LazyBlokPriceList],
["BlokPriceLists", LazyBlokPriceLists],
["BlokProfileCard", LazyBlokProfileCard],
["BlokProfileList", LazyBlokProfileList],
["BlokRichtext", LazyBlokRichtext],
["BlokTeaser", LazyBlokTeaser],
["BlokTeaserList", LazyBlokTeaserList],
["BlokTeaserSection", LazyBlokTeaserSection],
["BlokTextMediaSection", LazyBlokTextMediaSection],
["ContentTypeArticle", LazyContentTypeArticle],
["PageCookiePolicy", LazyPageCookiePolicy],
["PagePrivacy", LazyPagePrivacy],
["ThePage", LazyThePage],
["Icon", LazyIcon],
["IconCSS", LazyIconCSS],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
