<script setup>
import { localeUrlRegex, informationalUrls } from "@/helpers/urls";
import { useCookieBanner } from "@/composables/useCookieBanner";
import { useBreakpoints } from '@vueuse/core';


const breakpoints = useBreakpoints({
  xxs: 320,
  xs: 480,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1536
});
const { isNativeFallback } = useNativeFallback();
const { locales } = useI18n();
const config = useRuntimeConfig();
const breakpointReachesMaxWidth = breakpoints.greaterOrEqual('xl'); 

const imgPath = "/f/120421/4407x2938/a18effc51f/seitenhintergrund.jpg";

const bodyStyle = computed(() => {
  if (isNativeFallback.value)
    return `background-image: url(${config.public.NUXT_TWICPICS_DOMAIN}${imgPath})`;
  return undefined;
});

const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: "id",
  addSeoAttributes: true,
});

useHead({
  titleTemplate: '%s',
  bodyAttrs: {
    "data-twic-background": breakpointReachesMaxWidth.value ? `url(image:${imgPath}?output=webp)`: '',
    class: "bg-brandLight bg-fixed bg-no-repeat bg-cover font-sans text-font",
    style: bodyStyle,
  },
});

const route = useRoute();
const siteConfig = useSiteConfig();
const isLocaleInPath = localeUrlRegex.test(route.path);
const currentSlug = isLocaleInPath ? route.path.slice(3) : route.path;

const layout = computed(() => {
  if (informationalUrls.some((x) => x === currentSlug)) {
    return "informational";
  }
  return "default";
});

useCookieBanner();

useSchemaOrg([
  defineWebSite({
    name: siteConfig.name,
    inLanguage: locales.value.map((locale) => locale.iso),
    url: siteConfig.url,
  }),
  defineLocalBusiness({
    "@type": ["Organization", "LocalBusiness", "LodgingBusiness"],
    name: siteConfig.name,
    url: siteConfig.url,
    // logo: '',
    image:
      "https://bivignano.twic.pics/f/120421/3968x2976/b1e416f98a/bivignano-drohnenbild.jpg",
    legalName: "CASA BIVIGNANO DI WANNER PATRICIA",
    sameAs: [
      "https://www.facebook.com/p/Bivignano-100067821768199",
      "https://www.youtube.com/shorts/BCS8NYa8gcw",
      "https://bauernhofurlaub.info/ferien-bauernhof/agriturismo-casa-bivignano-toskana",
      "http://www.urlaub-anbieter.com/bivignano.htm",
      "https://www.reiten-weltweit.de/daten/exklusiv/haus.php?obj_id=dr02671&wh_id=2670",
    ],
    telephone: "+39 331 812 1272",
    email: "casa@bivignano.com",
    foundingDate: "2009-04-01T00:00:0+00:00",
    vatId: "01941150516",
    taxId: "WNNPRC69R68Z133Q",
    numberOfEmployees: {
      value: 2,
    },
    contactPoint: {
      telephone: "+39 331 812 1272",
      email: "casa@bivignano.com",
    },
    address: {
      streetAddress: "Loc. Bivignano, 6",
      addressLocality: "Molin Nuovo",
      addressRegion: "AR",
      postalCode: "52030",
      addressCountry: "IT",
    },
    currenciesAccepted: "€",
    openingHoursSpecification: {
      dayOfWeek: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
    },
  }),
]);
</script>

<template>
  <Html :lang="head.htmlAttrs.lang" :dir="head.htmlAttrs.dir">
    <Head>
      <template v-for="link in head.link" :key="link.id">
        <Link
          :id="link.id"
          :rel="link.rel"
          :href="link.href"
          :hreflang="link.hreflang"
        />
      </template>
      <template v-for="meta in head.meta" :key="meta.id">
        <Meta :id="meta.id" :property="meta.property" :content="meta.content" />
      </template>
    </Head>
    <Body>
      <NuxtLayout :name="layout">
        <NuxtPage />
      </NuxtLayout>
    </Body>
  </Html>
</template>
